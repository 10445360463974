<template>
    <div class="bg-white">
        <EnvBanner />
        <div class="h-screen flex flex-col overflow-auto overflow-x-hidden">
            <Circles />
            <Header class="z-50" />
            <main class="flex-1">
                <div class="relative z-40">
                    <!-- Your content -->
                    <slot />
                </div>
            </main>
            <Footer />
        </div>
        <!--<RequestLog />-->
    </div>
</template>

<script setup>
useHead({
    bodyAttrs: {
        class: 'h-full bg-gray-50'
    },
    htmlAttrs: {
        class: 'h-full'
    }
})

// Comment out to deactivate the help pages
//useHelpPage().listenForHelp()
</script>
